<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <div class="product">
        <h3>
          <typed-ment id="sysg" strings="水运施工数字化经营管理系统"></typed-ment>
        </h3>
        <h4>联系我们</h4>
        <contact-us></contact-us>
      </div>
    </div>
  </div>
</template>
<script>
import contactUs from '@/components/contactUs.vue'
export default {
  name: "sysg",
  components: {
    contactUs
  },
  data(){
    return {
    
    }
  },
  mounted() {

  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.up-banner{
  background:url("~@/assets/image/product/bg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
	width: 100%;
  height: calc(100vh - 80px);
  h3{
    color: #FFFFFF;
    font-size: 48px;
  }
  h4{
    color: #FFFFFF;
    top: 48%;
  }
  /deep/.lxwm{
    top: 56%;
    div{
      background-color: rgba($color: #FFFFFF, $alpha: 0.8);
    }
  }
}
</style>